.ProjectRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 0;
}

@media (max-width: 800px) {
  .ProjectRow {
    flex-direction: column;
  }
}