.Portfolio {
  background-image: linear-gradient(to bottom right, #1d2024, rgb(45, 50, 60));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 5% 5% 5% 8%;
  color: whitesmoke;
}

.TopBar {
  display: flex;
  height: 100%;
  flex-direction: row;
  width: 96%;
  position: relative;
  padding: 0;

}

@media (max-width: 800px) {
  .TopBar {
    width: 98%;
  }
}

.PortfolioText {
  font-size: 2.3ex;
}

.HomeButton {
  font-size: 2.5ex;
  position: absolute;
  right: 0;
  top: 33%;
}