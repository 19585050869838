.UnderDev {
  height: 20px;
  background-color: rgb(150, 50, 50);
  margin: 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 25px;
}

.UnderDevText {
  color: whitesmoke;
  text-align: center;
}

@media (max-width: 800px) {
  .UnderDev {
    min-height: 45px;
  }
}