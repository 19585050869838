.LinkButton {
  margin: 1.5%;
  min-width: 19%;
  min-height: 10vh;
}

.ButtonLink {
  text-decoration: none;
}

.InnerButton {
  font-size: 1.15ex;
  justify-content: center;
  color: whitesmoke;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 0.2ex #23272B solid;
  border-radius: 0.4ex;
}

.InnerButton:hover {
  opacity: 0.35;
  border: 0.2ex rgb(150, 50, 50) solid;
}

.LinkImage {
  border-radius: 0.4ex;
  height: 12ex;
  width: auto;
}