.About {
  background-image: linear-gradient(to bottom right, #1d2024, rgb(45, 50, 60));
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 5% 5% 5% 8%;
  color: whitesmoke;
}

.AboutText {
  font-size: 2.3ex;
  width: 80vw;
}

.LastUpdatedText {
  font-size: 1.8ex;
  color: rgb(150, 50, 50);
}