@import url(https://fonts.googleapis.com/css2?family=Raleway&family=Varela+Round&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Varela Round', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.NavBar {
  display: -webkit-flex;
  display: flex;
  background-color: whitesmoke;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 0;
}
.NavButton {
  display: -webkit-flex;
  display: flex;
  text-align: center;
  padding: 1ex;
  text-decoration: none;
  color: rgb(60, 60, 60);
  font-size: 2ex;
  font-weight: bold;
  padding: 10px 20px;
}

.NavButton:hover {
  background-color: lightgrey;
}
.GeneralButton {
  border: 0.2ex whitesmoke solid;
  border-radius: 0.3ex;
  padding: 0.5ex;
  margin-top: 1ex;
  text-decoration: none;
  font-size: 2ex;
  color: whitesmoke;
  box-shadow: inset 0 0 0 0 rgb(224, 64, 6);
  transition: all 0.4s;
}

.GeneralButton:hover {
  border-radius: 0.6ex;
  box-shadow: inset 300px 0 0 0 rgb(224, 64, 6);
  cursor: pointer;
}
.LinkBar {
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  margin: 5%;
}
.LinkButton {
  margin: 1.5%;
  min-width: 19%;
  min-height: 10vh;
}

.ButtonLink {
  text-decoration: none;
}

.InnerButton {
  font-size: 1.15ex;
  -webkit-justify-content: center;
          justify-content: center;
  color: whitesmoke;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border: 0.2ex #23272B solid;
  border-radius: 0.4ex;
}

.InnerButton:hover {
  opacity: 0.35;
  border: 0.2ex rgb(150, 50, 50) solid;
}

.LinkImage {
  border-radius: 0.4ex;
  height: 12ex;
  width: auto;
}
.Home {
  text-align: center;
  background-image: linear-gradient(to bottom right, #1d2024, rgb(45, 50, 60));
  display: -webkit-flex;
  display: flex;
  min-height: 100vh;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
  padding: 10% 9% 0 9%;
}

.HomeTitle { 
  font-size: 5ex;
}
.TitleBar {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 96%;
  position: relative;
  padding: 0;

}

@media (max-width: 800px) {
  .TitleBar {
    width: 98%;
  }
}

.HomeButton {
  font-size: 2.5ex;
  position: absolute;
  right: 0;
  top: 33%;
}
.About {
  background-image: linear-gradient(to bottom right, #1d2024, rgb(45, 50, 60));
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 5% 5% 5% 8%;
  color: whitesmoke;
}

.AboutText {
  font-size: 2.3ex;
  width: 80vw;
}

.LastUpdatedText {
  font-size: 1.8ex;
  color: rgb(150, 50, 50);
}
.ProjectTile {
  background-color: rgb(40, 40, 40);
  width: 45%;
  min-height: 35vh;
  margin: 4% 4% 0 0;
  -webkit-align-self: center;
          align-self: center;
  transition: all 0.3s;
  border: 0.5ex whitesmoke solid;
  border-radius: 1ex;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  position: relative;
}

.ProjectTile:hover {
  opacity: 0.3;
}

.ViewProject {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0ex;
}

.ViewProject:hover {
  opacity: 1;
}

.ViewProjectText {
  position: absolute;
  bottom: 1ex;
  width: 100%;
}


@media (max-width: 1400px) {
  .ProjectTile {
    min-height: 30vh;
  }
}

@media (max-width: 800px) {
  .ProjectTile {
    -webkit-flex-direction: column;
            flex-direction: column;
    width: 96%;
    min-height: 30vh;
  }
}
.ProjectRow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: left;
          justify-content: left;
  padding: 0;
}

@media (max-width: 800px) {
  .ProjectRow {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
.Portfolio {
  background-image: linear-gradient(to bottom right, #1d2024, rgb(45, 50, 60));
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 5% 5% 5% 8%;
  color: whitesmoke;
}

.TopBar {
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 96%;
  position: relative;
  padding: 0;

}

@media (max-width: 800px) {
  .TopBar {
    width: 98%;
  }
}

.PortfolioText {
  font-size: 2.3ex;
}

.HomeButton {
  font-size: 2.5ex;
  position: absolute;
  right: 0;
  top: 33%;
}
.UnderDev {
  height: 20px;
  background-color: rgb(150, 50, 50);
  margin: 0;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: 25px;
}

.UnderDevText {
  color: whitesmoke;
  text-align: center;
}

@media (max-width: 800px) {
  .UnderDev {
    min-height: 45px;
  }
}
