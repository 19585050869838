.GeneralButton {
  border: 0.2ex whitesmoke solid;
  border-radius: 0.3ex;
  padding: 0.5ex;
  margin-top: 1ex;
  text-decoration: none;
  font-size: 2ex;
  color: whitesmoke;
  box-shadow: inset 0 0 0 0 rgb(224, 64, 6);
  transition: all 0.4s;
}

.GeneralButton:hover {
  border-radius: 0.6ex;
  box-shadow: inset 300px 0 0 0 rgb(224, 64, 6);
  cursor: pointer;
}