.Home {
  text-align: center;
  background-image: linear-gradient(to bottom right, #1d2024, rgb(45, 50, 60));
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: whitesmoke;
  padding: 10% 9% 0 9%;
}

.HomeTitle { 
  font-size: 5ex;
}