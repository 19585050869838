.ProjectTile {
  background-color: rgb(40, 40, 40);
  width: 45%;
  min-height: 35vh;
  margin: 4% 4% 0 0;
  align-self: center;
  transition: all 0.3s;
  border: 0.5ex whitesmoke solid;
  border-radius: 1ex;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.ProjectTile:hover {
  opacity: 0.3;
}

.ViewProject {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0ex;
}

.ViewProject:hover {
  opacity: 1;
}

.ViewProjectText {
  position: absolute;
  bottom: 1ex;
  width: 100%;
}


@media (max-width: 1400px) {
  .ProjectTile {
    min-height: 30vh;
  }
}

@media (max-width: 800px) {
  .ProjectTile {
    flex-direction: column;
    width: 96%;
    min-height: 30vh;
  }
}